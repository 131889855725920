<template>
  <address-form type="OPERATING" />
</template>
<script>
import AddressForm from "@/views/steps/AddressForm";
export default {
  name: 'OperatingAddress',
  components: {
    AddressForm
  }
}
</script>
